#component.about.active {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/about-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-out;
  gap: 20px;
}

.about-top {
  height: 100%;
  width: 100%;
  padding-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.about-title {
  width: 35%;
}

.about-title h2 {
  font-size: 60px;
  text-transform: uppercase;
  font-style: italic;
}

.about-title h2:nth-child(1){
  font-weight: 100;
}

.about-title h2:nth-child(2){
  padding-left: 30px;
  font-weight: 400;
}

.testimonial-wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.testimonial {
  z-index: -1;
  opacity: 0;
}

.testimonial p {
  margin: 0;
  font-size: 24px;
  font-style: italic;
  line-height: 45px;
  height: 100%;
}

.testimonial p:nth-child(1) {
  font-weight: 100;
}

.testimonial p:nth-child(2) {
  font-weight: 500;
}

.testimonial.active {
  position: relative;
  opacity: 1;
  z-index: 1;
  width: 80%;
  transition: all 0.3s ease-out;
}

.testimonial.next { 
  position: absolute;
  left: 0;
  opacity: 0;
}

.testimonial.prev { 
  position: absolute;
  left: 0;
  opacity: 0;
}

.testimonial-arrow {
  background-image: url(../../assets/right-arrow.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  width: 50px;
  z-index: 50;
  margin-top: 150px;
  position: relative;
  top: 60px;
  right: 100px;
}

.about-bottom {
  height: 100%;
  width: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-bottom h3 {
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 10px;
}

.about-bottom p {
  font-size: 20px;
  padding-top: 40px;
  line-height: 30px;
  text-align: center;
}

@media screen and (max-width: 1450px) {
  .about-top{
    flex-direction: column;
    height: 49%;
    padding: 0;
    gap: 0;
  }

  .about-title { 
    padding-top: 4%;
    width: auto;
    height: 50%;
  }

  .testimonial-wrapper { 
    padding-left: 100px; 
    padding-top: 20px;
    width: 80%;
    height: 70%;
  }
  .testimonial { margin: auto; }
  .testimonial.active { 
    margin: 0; 
    margin-left: 40px;
    width: 80%;
  }
  .testimonial-arrow { top: 15px; }
  .about-bottom p { padding-top: 15px; }
}

@media screen and (max-width: 1024px) {
  .testimonial p {
    font-size: 18px;
  }
  .testimonial-arrow { top: -24px; }
  .about-bottom p {
    font-size: 14px;
  }
}

@media screen and (max-width: 990px) {
  #component.about.active {  height: 900px; }
  .testimonial-wrapper { 
    padding-left: 50px; 
    width: 100%;
    height: 80%;
  }
}

@media screen and (max-width: 780px) {
  #component.about.active {  height: 1200px; }
  .testimonial p { font-size: 24px; }
}

@media screen and (max-width: 420px) {
  #component.about.active {  height: 1300px; }
  .about-title h2 {
    font-size: 40px;
  }
  .testimonial p {
    font-size: 20px;
  }
  .testimonial.active { margin: 0; }
}