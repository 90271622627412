.listings {
  height: 100%;
  transition: all 0.3s ease-out;
}

.slideshow-container {
  margin: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.listing-slides {
  white-space: nowrap;
  transition: ease 1000ms;
  height: 100%;
}

.slide {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.portfolio-btn-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.portfolio-btn-wrapper h2:nth-child(1) {
  font-size: 50px;
  font-weight: 300;
  text-transform: uppercase;
  font-style: italic;
  margin: 0 100px 0 0;
  color: white;
  position: absolute;
  top: 50px;
  left: 210px;
}

.portfolio-btn-wrapper h2:nth-child(2) {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  margin: 0 0 0 60px;
  color: white;
  position: absolute;
  top: 100px;
  left: 200px;
}

.portfolio-btn {
  text-decoration: none;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 25px 40px;
  background-color: rgba(35, 35, 35, 0.8);
  margin: 0 auto;
}

.img1,
.img2,
.img3,
.img4,
.img5 {
  transition: ease 1000ms;
}

.img1 {
  background-image: url(../../assets/listing-01.jpg);
}
.img2 {
  background-image: url(../../assets/listing-02.jpg);
}
.img3 {
  background-image: url(../../assets/listing-03.jpg);
}
.img4 {
  background-image: url(../../assets/listing-04.jpg);
}
.img5 {
  background-image: url(../../assets/listing-05.jpg);
}

@media screen and (max-width: 990px) {
  .portfolio-btn-wrapper h2:nth-child(1) { 
    position: static;
    margin: 0 auto;
  }
  .portfolio-btn-wrapper h2:nth-child(2) { 
    position: static;
    margin: 0 auto;
  }
  .portfolio-btn { 
    position: static;
  }
}

@media screen and (max-width: 766px) {
  
}