.nav {
  height: 100%;
  position: absolute;
  top: 0px;
  background: linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 100;
}

.black-bar {
  display: block;
  width: 40px;
  min-height: 100%;
  background-color: black;
  z-index: -1;
  position: fixed;
}

.main-logo {
  display: block;
  width: 150px;
  z-index: 10;
}

.main-logo img {
  width: 100%;
  height: 100%;
}

.scroll-navigation {
  padding-left: 40px;
  position: relative;
}

.nav-menu {
  margin-top: 25px;
  position: static;
}

.nav-menu .active {
  font-weight: 700;
}

.nav-menu li {
  display: block;
  width: 20%;
  height: auto;
  padding: 17px 0;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 4.9px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  color: #f2ede7;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.nav-menu li:hover { 
  font-weight: 700;
}

.nav-menu-indicator {
  position: absolute;
  background-color: #fff;
  top: 0px;
  left: 36px;
  width: 4px;
  height: 48px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.pos-1 { transform: translate3d(0px, 0%, 0px); }
.pos-2 { transform: translate3d(0px, 100%, 0px); }
.pos-3 { transform: translate3d(0px, 200%, 0px); }
.pos-4 { transform: translate3d(0px, 300%, 0px); }
.pos-5 { transform: translate3d(0px, 400%, 0px); }

.mobile-menu-btn {
  display: none;
  position: absolute;
  top: 20%;
  left: 5%;
  width: 50px;
  height: 45px;
  border: 1px solid #3a3a3a;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.mobile-menu-btn span {
  display: block;
  position: absolute;
  height: 4px;
  width: 60%;
  background: #aeaeae;
  border-radius: 1px;
  opacity: 1;
  left: 20%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.mobile-menu-btn span:nth-child(1) {
  top: 12px;
  width: 46%;
  left: 34%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-btn span:nth-child(2) {
  top: 22px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-btn span:nth-child(3) {
  top: 32px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.mobile-menu-btn.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 9px;
  left: 30%;
  width: 60%;
}
.mobile-menu-btn.open span:nth-child(2){ display: none;}
.mobile-menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 30px;
  left: 30%;
}

.hidden {
  visibility: hidden;
  display: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.socials {
  z-index: 10;
  position: absolute;
  top: 25px;
  right: 35px;
  text-align: right;
}

.socials ul {
  width: 20%;
  padding: 0;
  margin: 0;
  border: 0;
}

.socials ul li {
  display: block;
  margin-top: 17px;
}

.socials ul li a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 30px;
}

.socials.about-page ul li a {
  filter: invert(100%);
}

.socials ul li a:hover {
  opacity: 0.6;
}

a.instagram {
  content: url(../../assets/ig-logo.png);
}

a.facebook {
  content: url(../../assets/fb-logo.png);
}

a.youtube {
  content: url(../../assets/yt-logo.png);
}

a.email {
  content: url(../../assets/mail-logo.png);
}

a.phone {
  content: url(../../assets/phone-logo.png);
  rotate: 180deg;
}

@media screen and (max-width: 990px) {
  .nav {
    height: 100%;
    width: 100%;
    background: rgb(69, 69, 69);
    display: block;
    position: sticky;
  }
  .main-logo {
    margin: 0 auto;
    height: 100%;
    width: 100px;
  }
  .main-logo img {
    height: 100%;
    width: 100%;
  }
  .black-bar { display: none; }
  .mobile-menu-btn { display: block;}
  .nav-menu { 
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 30px 0;
    background-color: #3a3a3a;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .nav-menu li{
    margin: auto;
    text-decoration: none;
    text-align: center;
  }
  .nav-menu-indicator { 
    display: none; 
  }
  .scroll-navigation { padding: 0; }
  .socials {
    top: 20%;
  }
}

@media screen and (max-width: 420px) {
  .nav-menu li { width: 40%; }
  .socials {
    top: 15%;
    right: 15px;
  }
}
