#component.services.active {
  height: 100vh;
  width: 100%;
  background-color: black;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 50% 50%;
  justify-content: space-evenly;
  transition: all 0.3s ease-out;
}

.services > div {
  height: 100%;
  width: 100%;
}

.services div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.video-link {
  position: inherit;
  grid-area: 1 / 1 / span 1 / span 2;
  filter: grayscale(40%);
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
  animation: fadeInRight 1s both;
}

.video-link a {
  width: 100%;
  height: 100%;
}

.video-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.video-link img:nth-child(2) {
  transform: scaleX(-1);
}

.video-link .play-btn {
  position: absolute;
  top: 40%;
  right: 45%;
  width: 80px;
  height: 80px;
  z-index: 10;
  filter: invert(100%);
}

.video-link:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.service-title {
  grid-area: 1 / 3 / span 1 / span 1;
  color: black;
  background-color: white;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  font-size: 30px;
  z-index: 10;
  animation: fadeInTop 1s both;
}

.service-title h2 {
  text-transform: uppercase;
  font-style: italic;
  margin: 0 auto;
  padding: 0;
}

.service-title h2:nth-child(1) {
  font-weight: 100;
  padding: 20px 8px 0;
}

.service-title h2:nth-child(2) {
  font-weight: 400;
  padding-left: 60px;
  padding-bottom: 20px;
}

.service-title p {
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px;
}

.couch-wrapper {
  grid-area: 1 / 4 / span 1 / span 1;
  animation: fadeInLeft 1s both;
}

.couch-wrapper img {
  z-index: 10;
}

.glasses-wrapper {
  grid-area: 2 / 1 / span 1 / span 1;
  animation: fadeInRight 1s both;
}

.services .glasses-wrapper img {
  object-position: top;
  margin-bottom: -4px;
  filter: grayscale(40%);
  opacity: 0.8;
}

.review-wrapper {
  grid-area: 2 / 2 / span 1 / span 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  animation: fadeInBottom 1s both;
}

.review-wrapper > p {
  height: 40%;
  padding: 10px;
  font-style: italic;
  word-spacing: 1.5px;
  text-align: center;
  line-height: 22px;
  font-size: 22px;
  margin: 0;
}

.services .review-wrapper img {
  height: 44%;
  width: 100%;
  object-fit: cover;
}

.media-wrapper {
  grid-area: 2 / 3 / span 1 / span 2;
  text-align: left;
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: black;
  background-image: url(../../assets/listing-01.jpg);
  background-size: cover;
  animation: fadeInLeft 1s both;
}

.media-wrapper::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.75);
}

.media-wrapper h3 {
  color: white;
  margin: 30px 0 30px 100px;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 30px;
}

.press-media {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  height: 45%;
  width: 80%;
  margin: auto;
}

.press-media a {
  width: 25%;
  animation: fadeInLeft 2s both;
}

.press-media a:nth-child(1) {
  position: inherit;
  width: 45%;
  filter: grayscale(60%);
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

.press-media a:nth-child(1):hover {
  filter: grayscale(0%);
  opacity: 1;
}

.press-media a > img {
  width: 100%;
  height: 100%;
}

.press-media a .play-btn {
  position: absolute;
  top: 35%;
  right: 35%;
  width: 80px;
  height: 80px;
  filter: invert(100%);
}

fadeInBottom {
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

fadeInTop {
  animation-name: fadeInTop;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@media screen and (max-width: 1024px) {
  .review-wrapper > p {
    font-size: 11px;
  }
}

@media screen and (max-width: 990px) {
  .video-link,
  .service-title,
  .couch-wrapper,
  .glasses-wrapper,
  .review-wrapper,
  .media-wrapper,
  .press-media a {
    animation: none;
  }
  #component.services.active {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .services > div {
    height: 100%;
  }

  .services .service-title {
    z-index: 1;
    position: inherit;
    padding: 80px 0;
    font-size: 25px;
  }

  .service-title p {
    text-align: center;
    margin: auto;
  }

  .services .couch-wrapper {
    height: 50%;
  }
  .services .couch-wrapper img {
    object-position: top;
  }

  .glasses-wrapper {
    display: none;
  }

  .services .review-wrapper {
    justify-content: space-between;
    height: 40%;
    z-index: 1;
  }
  .services .review-wrapper > p {
    padding: 10px 20px;
    font-size: 24px;
  }
  .services .review-wrapper > img {
    height: 60%;
  }

  .press-media {
    margin: 80px auto;
  }
}

@media screen and (max-width: 600px) {
  .services .review-wrapper > p {
    font-size: 16px;
  }
}

@media screen and (max-width: 420px) {
  .video-link .play-btn {
    position: absolute;
    top: 40%;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    filter: invert(100%);
  }
  .services .review-wrapper > p {
    padding: 10px 10px;
    font-size: 14px;
  }
  .services .media-wrapper {
    display: flex;
    flex-direction: column;
    height: 800px;
  }
  .media-wrapper h3 {
    margin: 30px;
    font-size: 20px;
  }
  .press-media {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding-top: 30%;
  }
  .press-media a {
    width: 50%;
  }
  .press-media a:nth-child(1) {
    width: 80%;
  }
  .press-media a .play-btn {
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px;
  }
}

@media screen and (max-width: 350px) {
  .services .review-wrapper > p {
    font-size: 12px;
  }
}
