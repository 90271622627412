.welcome {
  background-image: url('../../assets/slideshow-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  height: 100%;
  transition: all 0.3s ease-out;
}

.name-header {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 65%;
}

.name-header h1 {
  color: white;
  font-family: 'Barlow', sans-serif;
  font-size: 45px;
  text-transform: uppercase;
}

.name-header h1:nth-child(1) {
  font-weight: 200;
}
.name-header h1:nth-child(2) {
  font-weight: 500;
}

.brokerage {
  position: absolute;
  top: 90%;
  left: 8%;
  width: 20%;
}

.brokerage img {
  width: 100%;
}

.trecContainer {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.trecColor {
  color: white;
}

@media screen and (max-width: 1700px) and (max-height: 1450px) {
  .name-header {
    top: 5%;
    left: 15%;
  }
}

@media screen and (max-width: 990px) {
  .welcome {
    background-attachment: scroll;
  }
  .name-header {
    left: 5%;
    top: 60%;
  }
  .name-header h1 {
    font-size: 25px;
  }
  .welcome .brokerage {
    top: 90%;
    left: 75%;
    margin: auto;
  }
}

@media screen and (max-width: 420px) {
  .name-header {
    top: 64%;
  }
  .name-header h1 {
    font-size: 20px;
  }
  .welcome .brokerage {
    top: 90%;
  }
}
