.connect {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/connect-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: white;
}

.connect-title {
  position: absolute;
  top: 100px;
  left: 250px;
}

.connect-title h2{
  font-size: 75px;
  text-transform: uppercase;
  font-style: italic;
}

.connect-title h2:nth-child(1){
  font-weight: 400;
}

.connect-title h2:nth-child(2){
  padding-left: 100px;
  font-weight: 100;
}

.connect-content {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.a-list {
  text-align: center;
  max-width: 80%;
}

.a-list h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-style: italic;
  margin: 5px auto;
  font-size: 22px;
}

.a-list p {
  padding: 5px 0 30px 0;
  font-size: 14px;
  letter-spacing: 1px;
}

.connect form {
  display: flex;
}

.connect-input {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.connect-input input,
.connect-input textarea {
  padding: 0 10px;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  resize: none;
  color: white;
  font-family: "Barlow", sans-serif;
}

.connect-input input:focus,
.connect-input textarea:focus {
  outline: none;    
}

.connect-input textarea { width: 300px;}

.connect-content form .submit-btn {
  border: none;
  background-color: white;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 20px;
  font-weight: 600;
  letter-spacing: .5px;
}

.connect .brokerage {
  position: absolute;
  top: 90%;
  left: 10%;
  width: 20%;
}

.connect .brokerage img {
  width: 100%;
}

@media screen and (max-width: 990px) {
  #component.connect.active{
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: 100%;
  }

  .connect-title {
    position: inherit;
    margin: 0 auto;
    text-align: center;
  }

  .connect-title h2:nth-child(2) {
    padding: 0;
  }

  .connect-content {
    position: absolute;
    top: 56%;
    left: 0;
    right: 0;
    text-align: center;
  }

  .a-list p { padding: 10px 50px 20px; }

  .connect-content form {
    flex-direction: column;
  }

  .connect-input input { 
   margin: 10px 0; 
   height: 30px;
  }
  .connect-input textarea {
    height: 40px;
  }

  .connect .brokerage { left: 5%; } 
}
